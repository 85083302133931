import { Nullable }		from "ts-base/nullable";

import { Refresh }		from "@v3/preact/refresh";

import * as i18n		from "@geotoura/shared/i18n";

import { Messages }		from "@geotoura/nav/locale";
import { Model }		from "@geotoura/nav/model";
import { App }			from "@geotoura/nav/App";
import * as actions		from "@geotoura/nav/actions";

const init	= ():void	=> {
	const languageCode:i18n.LanguageCode	=
		i18n.LanguageCode.valueOrDefault(document.documentElement.getAttribute("lang"));

	const msg	= Messages.of[languageCode];

	const canHover:boolean = window.matchMedia("(any-hover: hover)").matches;
	const screenSize =
		window.innerWidth >= 1200	? "desktop"	:
		window.innerWidth >= 768	? "tablet"	:
		"mobile";

	const container	= Nullable.unsafeGet(document.querySelector<Element>("#nav"));

	const refresh:Refresh<Model> =
		Refresh.install({
			container:	container,
			initial:	Model.initial(screenSize, canHover),
			component:	model => <App languageCode={languageCode} messages={msg} model={model}/>,
		});
	actions.setRefresh(refresh);

	void actions.getData(languageCode);
};

window.addEventListener("DOMContentLoaded", init);
