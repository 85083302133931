import { JSX }	from "preact";

import { Dom }			from "ts-base/web/dom";

import { Button }			from "@v3/geko/Button";

import { permissions }	from "@geotoura/shared/authorization";

import { useMessages }	from "@geotoura/nav/useMessages";
import * as actions		from "@geotoura/nav/actions";
import { Model }		from "@geotoura/nav/model";
import { MobileList }	from "@geotoura/nav/MobileList";

export type MobileProps	= Readonly<{
	model:	Model,
}>;

// TODO a11y nav must communicate open and closed states
export const Mobile = ({ model }:MobileProps):JSX.Element => {
	const msg = useMessages();

	return (
		<>
			<div class="nav-inner">
				<Button
					customClass="nav-toggle-button"
					label={msg.title}
					layout="always-both"
					icon={model.menu ? "fa-angle-down" : "fa-angle-right"}
					style="link"
					permission={permissions.useWebsite}
					action={() => actions.toggleMenu()}
				/>
				<a title={msg.mainTitle} href={msg.mainUrl} aria-label={msg.mainTitle}>
					<img class="nav-logo" src={`/img/common/${msg.logo}`} alt=""/>
				</a>
			</div>
			<div class={Dom.classes(
				"nav-mobile-area",
				model.menu && "open"
			)}>
				{ model.app !== null && model.menu &&
					<MobileList app={model.app}/>
				}
			</div>
		</>
	);
};
