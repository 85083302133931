import { JSX }			from "preact";

import { Arrays }		from "ts-base/arrays";
import { Maybe }		from "ts-base/maybe";

import { ExternalLink }		from "@v3/geko/ExternalLink";

import * as wpModel		from "@geotoura/shared/wpModel";
import * as commonModel	from "@geotoura/shared/commonModel";

import * as actions		from "@geotoura/nav/actions";
import { useMessages }	from "@geotoura/nav/useMessages";
import { AppModel }		from "@geotoura/nav/model";

export type TabletListProps	= Readonly<{
	app:	AppModel,
}>;

export const TabletList = ({ app }:TabletListProps):JSX.Element => {
	const msg = useMessages();

	const featuredTerritory = app.selectedTerritoryId === null
		? app.data.territories[0]
		: Maybe.toNullable(Arrays.find(app.data.territories)((it) => it.id === app.selectedTerritoryId));

	return (
		<div class="nav-tablet-area-inner">
			<ul class="nav-tablet-territories">
				{ app.data.territories.map((t) =>
					territory(
						t,
						app.data.regions.filter((r) =>
							r.territoryId === t.id && r.name !== t.name
						),
						app.selectedTerritoryId,
						msg.hideRegions,
						msg.showRegions
					)
				)}
			</ul>
			<div class="nav-tablet-territory-description">
				{ featuredTerritory !== null && description(featuredTerritory, "t", msg.discover)}
			</div>
		</div>
	);
};

const territory = (
		t:wpModel.Territory,
		regions:ReadonlyArray<wpModel.Region>,
		selectedTerritoryId:commonModel.TerritoryId|null,
		hide:string,
		show:string
	):JSX.Element =>
	<li class="nav-tablet-territory">
		<button
			class={`nav-tablet-territory-inner ${t.id === selectedTerritoryId ? "active" : ""}`}
			onClick={() => actions.selectTerritory(t.id)}>
			<div class="nav-tablet-link">{t.name}</div>
			{ regions.length > 0 &&
				<div class="nav-button">
					<div class={`fa-light fa-chevron-${ t.id === selectedTerritoryId ? "down" : "right"}`}></div>
					<span class="sr-only">{t.id === selectedTerritoryId ? hide : show}</span>
				</div>
			}
		</button>
		{ t.id === selectedTerritoryId &&
			<ul class="nav-tablet-regions">
				{ regions.map(region) }
			</ul>
		}
	</li>;

const region = (r:wpModel.Region):JSX.Element =>
	<li class="nav-tablet-region">
		<a class="nav-tablet-link" href={r.url}>{r.name}</a>
	</li>;

const description = (data:wpModel.Region|wpModel.Territory, type:"t"|"u", discover:string):JSX.Element =>
	<div class="nav-tablet-description animated-05 fadeIn">
		<div class="nav-description-image" style={{ backgroundImage: `url("/p/${type}/460/${data.id}_h.jpg")` }}>
			<div class="nav-description-title">{data.name}</div>
			<div class="nav-description-portrait" style={{ backgroundImage: `url("/p/${type}/460/${data.id}_e.jpg")` }}>
			</div>
		</div>
		<div class="nav-description-expert-role">{data.expertRole}</div>
		<div class="nav-description-expert-name">{data.expertName}</div>

		<div class="nav-description-text">
			{data.expertQuote}
		</div>
		<div class="nav-description-button-bar">
			<ExternalLink
				customClass="nav-tablet-description-button"
				label={`${data.name} ${discover}`}
				layout="always-label"
				style="primary"
				href={data.url}
			/>
		</div>
	</div>;
